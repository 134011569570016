import React from "react"
import { Link, graphql } from "gatsby"
import { ListGroup } from "react-bootstrap"
import Layout from "../components/layout"
import NovelInfo from "../components/NovelInfo"
import { Helmet } from "react-helmet"
export const getNovels = graphql`
  {
    allContentfulNovel {
      nodes {
        id
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          title
        }
        info {
          info
        }
        link
      }
    }
  }
`

const novel = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Novel - Pamela Mary Brown</title>
      </Helmet>
      <h2>Novel</h2>

      <Link to="/">Go back to the homepage</Link>
      <ListGroup variant="flush">
        {data.allContentfulNovel.nodes.map(novel => {
          return (
            <ListGroup.Item
              style={{ border: "none", paddingLeft: "0px" }}
              key={novel.id}
            >
              <NovelInfo
                image={novel.image.fluid}
                title={novel.image.title}
                info={novel.info.info}
                link={novel.link}
              />
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </Layout>
  )
}

export default novel
