import React from "react"
import { Card, Row, Col, Container } from "react-bootstrap"
import Image from "gatsby-image"

const NovelInfo = ({ image, title, info, link }) => {
  return (
    <Card style={{ border: "none" }}>
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            style={{ paddingLeft: "0px" }}
          >
            <Image fluid={image} alt={title} />
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
          >
            <Card.Title>{title}</Card.Title>
            <Card.Text>{info}</Card.Text>
            <Card.Text>Purchase: {link}</Card.Text>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

export default NovelInfo
